import { ToastId, UseToastOptions, useToast } from '../chakra';
import { Poppins } from 'next/font/google';
import _ from 'lodash';

interface Toasts {
    toast: {
      (options?: UseToastOptions): ToastId,
      update(id: ToastId, options: Omit<UseToastOptions, 'id'>): void,
      close: (id: ToastId) => void,
      isActive: (id: ToastId) => boolean,
    };
    infoToast: (description: string, title?: string, options?: UseToastOptions) => ToastId;
    successToast: (description: string, title?: string, options?: UseToastOptions) => ToastId;
    warningToast: (description: string, title?: string, options?: UseToastOptions) => ToastId;
    errorToast: (description: string, title?: string, options?: UseToastOptions) => ToastId;
}

const poppins = Poppins({
  weight: ['400', '500', '600'],
  subsets: ['latin'],
});

export function useToasts(defaultConfig?: { fontFamily: string }): Toasts {
  const toastHook = useToast();

  const defaultOptions: UseToastOptions = {
    isClosable: true,
    containerStyle: {
      fontFamily: defaultConfig?.fontFamily ?? poppins.style.fontFamily,
    },
  };
  
  const errorToast = (description: string, title?: string, options?: UseToastOptions) => {
    return toastHook({
      status: 'error',
      title,
      description,
      ..._.merge(defaultOptions, options)
    });
  };

  const warningToast = (description: string, title?: string, options?: UseToastOptions) => {
    return toastHook({
      status: 'warning',
      title,
      description,
      ..._.merge(defaultOptions, options)
    });
  };

  const infoToast = (description: string, title?: string, options?: UseToastOptions) => {
    return toastHook({
      status: 'info',
      title,
      description,
      ..._.merge(defaultOptions, options)
    });
  };

  const successToast = (description: string, title?: string, options?: UseToastOptions) => {
    return toastHook({
      status: 'success',
      description,
      title,
      isClosable: true,
      ..._.merge(defaultOptions, options)
    });
  };

  // Wrap the other hook


  return {
    toast: toastHook,
    infoToast,
    successToast,
    warningToast,
    errorToast,
  };
}
