import { tokens } from '../../styles/tokens';
import { Button } from '@medsimples/design-system/src/components';
import { Box } from '@medsimples/design-system/src/chakra';

interface Props {
  router: any;
}

interface OptionalProps {
  color?: string;
  marginTop?: number;
  marginBottom?: number;
  action?: () => void;
}

const defaultProps: Required<OptionalProps> = {
  color: tokens.button.terciary.scheme,
  action: null,
  marginTop: 0,
  marginBottom: 5,
};

export default function BackButton(props: Props & OptionalProps) {
  props = { ...defaultProps, ...props };

  const back = () => {
    props.router.back();
    props.router.refresh();
  };

  return (
    <Box w="100%" marginTop={props.marginTop} marginBottom={props.marginBottom}>
      <Button 
        onClick={() => props.action ? props.action() : back()}
        backgroundColor={tokens.button.terciary.scheme} 
        _hover={{backgroundColor: tokens.button.terciary.colors.hover}}>
        Voltar
      </Button>
    </Box>
  );
}
