interface props {
  color: string
  width: number
  height: number
}

export default function Email({ color, width, height }: props) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 28 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.46094 0H25.5391C26.896 0 28 1.32362 28 2.95055V20.0494C28 21.6764 26.896 23 25.5391 23H2.46094C1.10398 23 0 21.6764 0 20.0494V2.95055C0 1.32362 1.10398 0 2.46094 0ZM2.78212 1.96703L3.11057 2.29494L13.0236 12.1918C13.5894 12.7566 14.4107 12.7566 14.9764 12.1918L24.8894 2.29494L25.2179 1.96703H2.78212ZM26.3594 3.3871L18.2825 11.4508L26.3594 17.8957V3.3871ZM2.46094 21.033H25.5391C25.9354 21.033 26.267 20.6941 26.3429 20.2454L16.967 12.7641L16.0262 13.7034C15.4392 14.2895 14.7195 14.5825 13.9999 14.5825C13.2804 14.5825 12.5608 14.2895 11.9737 13.7034L11.0329 12.7641L1.65703 20.2454C1.73305 20.6942 2.06456 21.033 2.46094 21.033ZM1.64062 17.8957L9.71753 11.4509L1.64062 3.3871V17.8957Z"
        fill={color}
      />
    </svg>
  )
}
