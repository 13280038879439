interface props {
  color: string
  width: number
  height: number
}

export default function Phone({ color, width, height }: props) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.85719 11.4852C8.83856 11.2605 5.76777 7.88691 5.59525 7.0277C5.42272 6.1685 6.69614 5.67209 6.68777 5.08262C6.6794 4.49315 4.71333 1.24965 4.05862 1.07506C3.40391 0.900476 1.3136 2.75979 1.27957 4.15767C1.2486 5.42934 2.97438 8.65889 5.42363 11.2982C7.42481 13.4547 10.9694 15.8843 12.4455 15.9202C13.9216 15.9562 15.9042 13.8513 15.7818 13.2818C15.6593 12.7122 12.7128 10.4714 12.0559 10.3874C11.3989 10.3035 10.4565 11.6174 9.85719 11.4852Z"
        stroke={color}
      />
    </svg>
  )
}
